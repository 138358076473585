import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import YouTube from '../images/youtube.png';
import Email from '../images/email.png';
import SEO from '../components/seo'
//import { relayInit } from 'nostr';
//import { SimplePool } from 'nostr-tools/pool';
import { NostrProvider, useNostrEvents, dateToUnix } from 'nostr-react';
import NOSTR_IMG from '../images/nostr.png';

const Note = ({ content, created_at, id, kind, pubkey, sig, tags }) => {
    // Extract image URLs from tags
    const imageTags = tags.filter(tag => tag[0] === 'r');
    const imageUrls = imageTags.map(tag => tag[1]);
//<div className="note-pubkey">{pubkey}</div>
    return (
        <div className="note" key={id}>
            <div className="note-header">
                <div className="note-date">{new Date(created_at * 1000).toLocaleString()}</div>
            </div>
            <div className="note-content">{content}</div>
            <div className="note-images">
                {imageUrls.map((url, index) => (
                    <img src={url} alt={`Note Image ${index + 1}`} key={index} className="note-image"/>
                ))}
            </div>
        </div>
    );
};


const publicKey = "0a69cf2560597cd4dfff9a75f40261d902a91b139cdacea10d54a52b43219250";
//const publicKey = "npub1pf5u7ftqt97dfhllnf6lgqnpmyp2jxcnnndvaggd2jjjksepjfgqwrpnfw";

const relays = [
        'wss://relay.damus.io',
        'wss://nostr-pub.wellorder.net',
	"wss://relay.nostr.bg",
	"wss://nos.lol",
    ];



/*async function fetchRecentNotes(publicKey, callback ) {

    const relays = [
        'wss://relay.damus.io',
        'wss://nostr-pub.wellorder.net',
	"wss://relay.nostr.bg",
	"wss://nos.lol",
    ];

    const pool = new SimplePool();
    const subscriptionTimeout = 6000; // 10 seconds

    let notes = [];

    let h = pool.subscribeMany(
        relays,
        [
            {
                kinds: [1],
                authors: [publicKey]
            }
        ],
        {
            onevent(event) {
                if (event.pubkey === publicKey && event.kind === 1 && !event.tags.some(tag => tag[0] === 'e')) {
                    if (!notes.some(note => note.id === event.id)) {
                        notes.push(event);
                    }
                }

                // Limit to 10 notes
                if (notes.length >= 10) {
                    h.close();
                }
            },
            oneose() {
                h.close();
            }
        }
    );

    // Cancel the subscription after 10 seconds
    setTimeout(() => {
        h.close();
        console.log('Subscription canceled after 10 seconds.');
        console.log('Fetched notes:', notes);
	callback( notes );
    }, subscriptionTimeout);
}*/

const getTwoMonthsAgoTimestamp = () => {
  const now = new Date();
  now.setMonth(now.getMonth() - 2);
  return now;//Math.floor(now.getTime() / 1000); // Convert to Unix timestamp
};

const ProfileFeed = ( props ) => {
  	const now = useRef( getTwoMonthsAgoTimestamp() );
	const { events } = useNostrEvents({
		filter: {
			authors: [publicKey],
			since: dateToUnix(now.current),
			kinds:[1]
		}
	})
	const filtered = events.filter(event => {
		return !event.tags.some(tag => tag[0] === 'e' );
	});
	
	return (
    		<div style={{padding:`0 2em`}}>
		<FeedHeader email={ "c.lacdael@poetry.me.uk" }
			publicKey={"npub1pf5u7ftqt97dfhllnf6lgqnpmyp2jxcnnndvaggd2jjjksepjfgqwrpnfw"} />
		{ ( filtered.length == 0 ) ? <p>Fetching NoStr posts ...</p>
		: filtered.map( (e,i) => <Note key={e.id} {...e} />  ) }
		</div>
	  );
};





const FeedHeader = ({ email, publicKey }) => {
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Copied to clipboard!');
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    return (
        <div className="feed-header">
	    <img src={ NOSTR_IMG } className="header-icon"/>
            <div className="feed-header-tems" >
	    <div className="feed-header-item">
                <strong>NoStr:&nbsp;</strong> { email}
            </div>
            <div className="feed-header-item">
                <strong>Pub:&nbsp; </strong><span className="pubkey-ellipsis"> {publicKey}</span>
                <button onClick={() => copyToClipboard(publicKey)} className="copy-button">
			&#128203;
                </button>
            </div>
		</div>
        </div>
    );
};


const ContactPage = ({ data }) => {
 
  const [events, setEvents] = useState([]); 

  /*useEffect(() => {
	fetchRecentNotes(publicKey , (notes) => {
	   setEvents( notes ); 
	   console.log('Final notes:', notes);
	});
	  //.catch(error => {
	    //console.error('Error fetching notes:', error);
	//});
  }, []);*/

return (
    <NostrProvider relayUrls={relays} debug={true}>
    <Layout>
    <SEO title="Contact | poetry.me" description="Contact the poet." />
 		 <ul className="breadcrumb">
        <li><Link to="/">Home</Link></li>
        <li>Contact</li>
        </ul>
      <div className="links">
                <a href="mailto:c.lacdael@poetry.me.uk" _target="blank" ><img src={Email} alt="email" /><div>c.lacdael@poetry.me.uk</div></a>
                <Link to="https://www.youtube.com/channel/UC2ZESffB6c9dyOpQTgltAOg" target="_blank"><img src={YouTube} alt="video channel" /><div>*YouTube</div></Link>
            </div>

<em>*Currently looking at alternatives due to Youtube's morally indefensible activities.</em>
	<hr/>
	  <ProfileFeed events={ events } />
   </Layout>
  </NostrProvider>
 );
};


export default ContactPage;
